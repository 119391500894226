import { getTableColumns } from 'drizzle-orm';
import { toSnakeCase } from 'drizzle-orm/casing';
import { type AnyPgColumn, boolean, doublePrecision, index, integer, jsonb, pgTable, timestamp, varchar } from 'drizzle-orm/pg-core';
import { usersTable } from '#/db/schema/users';
import { nanoid } from '#/utils/nanoid';
import { organizationsTable } from './organizations';
import { projectsTable } from './projects';

export const tasksTable = pgTable(
  'tasks',
  {
    id: varchar().primaryKey().$defaultFn(nanoid),
    entity: varchar({ enum: ['task'] })
      .notNull()
      .default('task'),
    description: varchar().notNull(),
    keywords: varchar().notNull(),
    expandable: boolean().default(false).notNull(),
    summary: varchar().notNull(),
    type: integer().notNull(),
    impact: integer(),
    // order is a reserved keyword in Postgres, so we need to use a different name
    order: doublePrecision().notNull(),
    status: integer().notNull(),
    parentId: varchar().references((): AnyPgColumn => tasksTable.id),
    labels: jsonb().$type<string[]>().notNull().default([]),
    assignedTo: jsonb().$type<string[]>().notNull().default([]),
    organizationId: varchar()
      .notNull()
      .references(() => organizationsTable.id, {
        onDelete: 'cascade',
      }),
    projectId: varchar()
      .notNull()
      .references(() => projectsTable.id, {
        onDelete: 'cascade',
      }),
    createdAt: timestamp().defaultNow().notNull(),
    createdBy: varchar().references(() => usersTable.id, {
      onDelete: 'set null',
    }),
    modifiedAt: timestamp(),
    modifiedBy: varchar().references(() => usersTable.id, {
      onDelete: 'set null',
    }),
  },
  (table) => {
    return {
      tasksProjectIndex: index('idx_tasks_project').on(table.projectId),
      tasksKeywordsIndex: index('idx_tasks_keywords').on(table.keywords),
    };
  },
);

// Get table columns and convert to snake_case
export const tasksTableColumns = Object.fromEntries(
  Object.entries(getTableColumns(tasksTable)).map(([key, column]) => [toSnakeCase(column.name), key]),
);

export type TaskModel = typeof tasksTable.$inferSelect;
export type InsertTaskModel = typeof tasksTable.$inferInsert;
