import { defaultProps, filterSuggestionItems, insertOrUpdateBlock } from '@blocknote/core';
import { type BlockTypeSelectItem, createReactBlockSpec } from '@blocknote/react';
import { Highlighter } from 'lucide-react';
import type { CustomBlockNoteSchema, IconType } from '~/modules/common/blocknote/types';

// The Summary block.
export const Summary = createReactBlockSpec(
  {
    type: 'summary',
    propSchema: {
      textAlignment: defaultProps.textAlignment,
      textColor: defaultProps.textColor,
    },
    content: 'inline',
  },
  {
    render: (props) => (
      <div className="summary">
        {/*Rich text field for user to type in*/}
        <p className="bn-inline-content" ref={props.contentRef} />
      </div>
    ),
  },
);

// Slash menu item to insert an summary block
const insertSummarySlashMenu = (editor: CustomBlockNoteSchema) => ({
  title: 'Summary',
  onItemClick: () => {
    const existingSummaryBlock = editor.document.find((block) => block.type === 'summary');

    // Convert existing summary block to a paragraph if it exists
    if (existingSummaryBlock) editor.updateBlock(existingSummaryBlock, { type: 'paragraph' });

    // Insert the new summary block
    insertOrUpdateBlock(editor, { type: 'summary' });
  },
  aliases: ['title', 'summary', 'header'],
  group: 'Custom',
  icon: <Highlighter size={16} />,
});

// Side menu item to insert an summary block
export const insertSummarySideMenu = (): BlockTypeSelectItem => ({
  name: 'Summary',
  type: 'summary',
  isSelected: (block: { type: string }) => block.type === 'summary',
  icon: Highlighter as IconType,
});

export const getSlashSummaryItem = (query: string, editor: CustomBlockNoteSchema) => {
  // Gets all default slash menu items and Summary item
  return filterSuggestionItems([insertSummarySlashMenu(editor)], query);
};

export const onTypeChange = (editor: CustomBlockNoteSchema) => {
  const existingSummaryBlock = editor.document.find((block) => block.type === 'summary');
  // Convert existing summary block to a paragraph if it exists
  if (existingSummaryBlock) editor.updateBlock(existingSummaryBlock, { type: 'paragraph' });
};
