import { pgTable, timestamp, varchar } from 'drizzle-orm/pg-core';
import { usersTable } from '#/db/schema/users';
import { nanoid } from '#/utils/nanoid';
import { organizationsTable } from './organizations';

export const projectsTable = pgTable('projects', {
  id: varchar().primaryKey().$defaultFn(nanoid),
  entity: varchar({ enum: ['project'] })
    .notNull()
    .default('project'),
  slug: varchar().notNull(),
  name: varchar().notNull(),
  thumbnailUrl: varchar(),
  bannerUrl: varchar(),
  organizationId: varchar()
    .notNull()
    .references(() => organizationsTable.id, {
      onDelete: 'cascade',
    }),
  createdAt: timestamp().defaultNow().notNull(),
  createdBy: varchar().references(() => usersTable.id, {
    onDelete: 'set null',
  }),
  modifiedAt: timestamp(),
  modifiedBy: varchar().references(() => usersTable.id, {
    onDelete: 'set null',
  }),
});

export type ProjectModel = typeof projectsTable.$inferSelect;
export type InsertProjectModel = typeof projectsTable.$inferInsert;
