import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { ChevronDown, Palmtree, Search, Undo } from 'lucide-react';
import { type MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type GetTasksParams, getTasksList } from '~/api/tasks';

import { autoScrollForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import { extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { dropTargetForElements, monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { useSearch } from '@tanstack/react-router';
import { toast } from 'sonner';
import { AvatarWrap } from '~/modules/common/avatar-wrap';
import ContentPlaceholder from '~/modules/common/content-placeholder';
import { dialog } from '~/modules/common/dialoger/state';
import FocusTrap from '~/modules/common/focus-trap';
import { taskKeys, useTaskUpdateMutation } from '~/modules/common/query-client-provider/tasks';
import { BoardColumnHeader } from '~/modules/tasks/board/board-column-header';
import { ColumnSkeleton } from '~/modules/tasks/board/column-skeleton';
import {
  type ColumnDraggableData,
  type DropTarget,
  type SubtaskDraggableData,
  type TaskDraggableData,
  isColumnData,
  isSubtaskData,
  isTaskData,
} from '~/modules/tasks/board/helpers';
import CreateTaskForm from '~/modules/tasks/create-task-form';
import { getEdgeAndTargetOrder, getNewTaskOrder, getRelativeTaskOrder, openTaskPreviewSheet, sortTaskOrder } from '~/modules/tasks/helpers';
import TaskCard from '~/modules/tasks/task';
import type { TaskStates } from '~/modules/tasks/types';
import { Button } from '~/modules/ui/button';
import { ScrollArea, ScrollBar } from '~/modules/ui/scroll-area';
import { useWorkspaceQuery } from '~/modules/workspaces/helpers/use-workspace';
import { WorkspaceBoardRoute } from '~/routes/workspaces';
import { useNavigationStore } from '~/store/navigation';
import { useThemeStore } from '~/store/theme';
import { useWorkspaceStore } from '~/store/workspace';
import type { Column } from '~/store/workspace-ui';
import { defaultColumnValues, useWorkspaceUIStore } from '~/store/workspace-ui';
import type { Project, Task } from '~/types/app';
import { cn } from '~/utils/cn';
import { useSync } from './helpers/use-sync';
import ProjectActions from './project-actions';

interface BoardColumnProps {
  tasksState: Record<string, TaskStates>;
  project: Project;
  settings?: Column;
}

export const tasksQueryOptions = ({ projectId, orgIdOrSlug }: GetTasksParams) => {
  return queryOptions({
    queryKey: taskKeys.list({ projectId, orgIdOrSlug }),
    queryFn: async () =>
      await getTasksList({
        orgIdOrSlug,
        projectId,
      }),
  });
};

const getColumnTasksAndCounts = (tasks: Task[], showAccepted: boolean, showIced: boolean) => {
  // Use reduce to filter tasks and count accepted/iced
  const { filteredTasks, acceptedCount, icedCount } = tasks.reduce(
    (acc, task) => {
      const { status } = task;
      if (status === 6) acc.acceptedCount += 1;
      if (status === 0) acc.icedCount += 1;
      // filter by showAccepted & showIced
      if ((showAccepted && status === 6) || (showIced && status === 0) || (status !== 0 && status !== 6)) {
        acc.filteredTasks.push(task);
      }
      return acc;
    },
    { filteredTasks: [] as Task[], acceptedCount: 0, icedCount: 0 },
  );

  // Sort the filtered tasks
  filteredTasks.sort((a, b) => sortTaskOrder(a, b));

  return { filteredTasks, acceptedCount, icedCount };
};

export function BoardColumn({ project, tasksState, settings }: BoardColumnProps) {
  const { t } = useTranslation();
  const { taskIdPreview } = useSearch({
    from: WorkspaceBoardRoute.id,
  });

  const defaultTaskFormRef = useRef<HTMLDivElement | null>(null);
  const columnRef = useRef<HTMLDivElement | null>(null);
  const cardListRef = useRef<HTMLDivElement | null>(null);
  const scrollViewportRef = useRef(null);

  const { menu } = useNavigationStore();
  const { mode } = useThemeStore();
  const { searchQuery, selectedTasks, focusedTaskId } = useWorkspaceStore();
  const {
    data: { workspace },
  } = useWorkspaceQuery();
  const { changeColumn } = useWorkspaceUIStore();
  const [highLightProject, setHighLightProject] = useState(false);

  useSync(project);

  const { expandIced: showIced, expandAccepted: showAccepted } = useMemo(
    () => settings || defaultColumnValues,
    [settings?.expandIced, settings?.expandAccepted],
  );

  // Query tasks
  const { data, isLoading } = useSuspenseQuery(tasksQueryOptions({ projectId: project.id, orgIdOrSlug: project.organizationId }));

  const taskMutation = useTaskUpdateMutation();

  const { filteredTasks, acceptedCount, icedCount } = useMemo(() => {
    // Get the tasks from the data or default to an empty array
    const respTasks = data?.items || [];

    // Filter tasks based on the search query
    const filteredTasks = searchQuery.length ? respTasks.filter((t) => t.keywords.toLowerCase().includes(searchQuery.toLowerCase())) : respTasks;

    // Sort the filtered tasks and get the counts
    return getColumnTasksAndCounts(filteredTasks, showAccepted, showIced);
  }, [data, searchQuery, showAccepted, showIced]);

  const handleIcedClick = () => {
    changeColumn(workspace.id, project.id, {
      expandIced: !showIced,
    });
  };

  const handleAcceptedClick = () => {
    changeColumn(workspace.id, project.id, {
      expandAccepted: !showAccepted,
    });
  };

  const openCreateTaskDialog = (ref: MutableRefObject<HTMLDivElement | null>, mode: 'top' | 'embed' | undefined = 'top') => {
    dialog(
      <CreateTaskForm
        projectIdOrSlug={project.id}
        dialog
        onCloseForm={() =>
          changeColumn(workspace.id, project.id, {
            createTaskForm: false,
          })
        }
        defaultValues={mode === 'embed' ? { status: 0 } : {}}
      />,
      {
        id: `create-task-form-${project.id}`,
        drawerOnMobile: false,
        preventEscPress: true,
        className: 'p-0 w-auto shadow-none relative z-[104] rounded-none border-t-0 m-0 max-w-none',
        container: ref.current,
        containerBackdrop: false,
        hideClose: true,
      },
    );
    // Scroll to the element inside the ref when the dialog opens
    if (ref.current) ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  // Hides underscroll elements
  // 4rem refers to the header height
  const stickyBackground = <div className="sm:hidden left-0 right-0 h-4 bg-background sticky top-0 z-30 -mt-4" />;

  useEffect(() => {
    if (!taskIdPreview) return;
    setTimeout(() => openTaskPreviewSheet(taskIdPreview, project.organizationId), 0);
  }, []);

  useEffect(() => {
    if (!scrollViewportRef.current || !columnRef.current) return;
    return combine(
      dropTargetForElements({
        element: columnRef.current,
        getData: () => ({ dragItem: true, item: { projectId: project.id, tasks: filteredTasks }, type: 'column', itemType: 'project' }),
        canDrop: ({ source: { data } }) => isTaskData(data),
        onDragEnter: ({ self: { data: selfData }, source: { data: sourceData } }) => {
          if (!isTaskData(sourceData)) return;
          if (sourceData.item.projectId !== selfData.projectId) setHighLightProject(true);
        },
        onDragLeave: () => setHighLightProject(false),
        onDrop: () => setHighLightProject(false),
      }),
      autoScrollForElements({
        element: scrollViewportRef.current,
        getAllowedAxis: () => 'vertical',
      }),
      monitorForElements({
        canMonitor({ source: { data } }) {
          return (isTaskData(data) || isSubtaskData(data)) && !taskIdPreview;
        },
        async onDrop({
          location: {
            current: { dropTargets },
          },
          source: { data: sourceData },
        }) {
          // Identify the drop target types (task, subtask, or column)
          const taskTargetData = dropTargets.find((el): el is DropTarget<TaskDraggableData> => isTaskData(el.data))?.data ?? null;
          const subTaskTargetData = dropTargets.find((el): el is DropTarget<SubtaskDraggableData> => isSubtaskData(el.data))?.data ?? null;
          const columnTargetData = dropTargets.find((el): el is DropTarget<ColumnDraggableData> => isColumnData(el.data))?.data ?? null;

          // Exit early if no valid drop target exists or source data is incompatible
          if (!taskTargetData && !columnTargetData && !subTaskTargetData) return;
          if (!isTaskData(sourceData) && !isSubtaskData(sourceData)) return;

          // Extract the source item data and ensure it belongs to the current project
          const { item: sourceItem } = sourceData;
          if (sourceItem.projectId !== project.id) return;

          // Define the base mutation object with current item order as default change value
          const baseMutationObject = {
            id: sourceItem.id,
            orgIdOrSlug: workspace.organizationId,
            projectId: project.id,
            key: 'order' as const,
            data: sourceItem.order,
          };

          try {
            // Handle column drop
            if (columnTargetData && !taskTargetData) {
              const {
                item: { tasks, projectId },
              } = columnTargetData;

              // Determine the new order based on task status within the column
              const order = getNewTaskOrder(sourceItem.status, tasks);

              const mutationObject = {
                ...baseMutationObject,
                key: 'projectId' as const,
                data: projectId,
                order,
              };
              await taskMutation.mutateAsync(mutationObject);
              return;
            }

            // Handle task drop
            if (taskTargetData && columnTargetData) {
              const {
                item: { tasks },
              } = columnTargetData;
              const { item: targetTask } = taskTargetData;

              const pragmaticEdge = extractClosestEdge(taskTargetData);
              if (!pragmaticEdge) throw new Error('Invalid drop edge');

              // Calculate new order
              const { targetOrder, edge } = getEdgeAndTargetOrder(targetTask, sourceItem, pragmaticEdge, tasks);
              const newOrder = getRelativeTaskOrder(edge, tasks, targetOrder, sourceItem.id, sourceItem.status);

              // adjusting mutation obj for cross-column moves
              const mutationObject =
                project.id !== targetTask.projectId
                  ? { ...baseMutationObject, order: newOrder }
                  : { ...baseMutationObject, key: 'projectId' as const, data: targetTask.projectId, order: newOrder };

              // Execute the mutation with new order
              await taskMutation.mutateAsync(mutationObject);
              return;
            }

            // Handle subtask drop
            if (subTaskTargetData) {
              const { item: targetSubTask } = subTaskTargetData;

              const edge = extractClosestEdge(subTaskTargetData);
              if (!edge) throw new Error('Invalid drop edge');

              // Retrieve the current list of subtasks for the parent task
              const subtasks = filteredTasks.find((t) => t.id === targetSubTask.parentId)?.subtasks || [];
              const newOrder = getRelativeTaskOrder(edge, subtasks, targetSubTask.order, sourceItem.id);

              await taskMutation.mutateAsync({ ...baseMutationObject, data: newOrder });
            }
          } catch (err) {
            toast.error(t('common:error.reorder_resource', { resource: t('app:task') }));
          }
        },
      }),
    );
  }, [menu, data]);

  return (
    <div ref={columnRef} className="flex flex-col h-full max-sm:-mx-1.5 max-sm:pb-28">
      <BoardColumnHeader projectId={project.id}>
        <AvatarWrap className="max-sm:hidden h-6 w-6 text-xs" id={project.id} type="project" name={project.name} url={project.thumbnailUrl} />
        <div className="truncate leading-6">{project.name}</div>
        <ProjectActions project={project} openDialog={() => openCreateTaskDialog(defaultTaskFormRef)} />
      </BoardColumnHeader>
      <div
        className={cn(
          'flex-1 sm:h-[calc(100vh-146px)] relative rounded-b-none max-w-full bg-transparent group/column flex flex-col flex-shrink-0 snap-center opacity-100 sm:border',
          selectedTasks.length && 'is-selected',
          highLightProject && 'border-primary ',
        )}
      >
        {stickyBackground}

        <div className="h-full">
          {isLoading ? (
            <ColumnSkeleton />
          ) : (
            <ScrollArea id={project.id} className="h-full mx-[-.07rem]" viewPortRef={scrollViewportRef}>
              <ScrollBar />
              <div className="z-[104]" ref={defaultTaskFormRef} />

              <div
                data-show-iced={showIced}
                data-show-accepted={showAccepted}
                className="h-full flex flex-col group"
                id={`tasks-list-${project.id}`}
                ref={cardListRef}
              >
                {!!filteredTasks.length && (
                  <div className="flex flex-col flex-grow">
                    <Button
                      onClick={handleAcceptedClick}
                      variant="ghost"
                      disabled={!acceptedCount}
                      size="sm"
                      className="flex relative justify-start w-full rounded-none gap-1 border-b border-b-green-500/10 border-t border-t-transparent ring-inset focus-visible:ring-offset-0 bg-green-500/5 hover:bg-green-500/10 text-green-500 text-xs -mt-[.07rem]"
                    >
                      <span className="w-6 mr-1.5 text-center">{acceptedCount}</span>
                      <span>{t('app:accepted').toLowerCase()}</span>
                      {!!acceptedCount && (
                        <ChevronDown
                          size={16}
                          className="transition-transform absolute right-5 opacity-50 group-data-[show-accepted=true]:rotate-180"
                        />
                      )}
                    </Button>
                    <motion.div>
                      {filteredTasks.map((task) => {
                        return (
                          <motion.div key={task.id} layout="position" transition={{ duration: 0.3 }}>
                            <FocusTrap mainElementId={task.id} active={task.id === focusedTaskId}>
                              <TaskCard
                                task={task}
                                state={tasksState[task.id] ?? 'folded'}
                                isSelected={selectedTasks.includes(task.id)}
                                isFocused={task.id === focusedTaskId}
                                mode={mode}
                              />
                            </FocusTrap>
                          </motion.div>
                        );
                      })}
                    </motion.div>
                    <Button
                      onClick={handleIcedClick}
                      variant="ghost"
                      disabled={!icedCount}
                      size="sm"
                      className="flex relative justify-start w-full rounded-none gap-1 ring-inset focus-visible:ring-offset-0 text-sky-500 max-sm:border-b border-b-sky-500/10 bg-sky-500/5 hover:bg-sky-500/10 text-xs -mt-[.07rem]"
                    >
                      <span className="w-6 mr-1.5 text-center">{icedCount}</span>
                      <span> {t('app:iced').toLowerCase()}</span>
                      {!!icedCount && (
                        <ChevronDown size={16} className="transition-transform absolute right-5 opacity-50 group-data-[show-iced=true]:rotate-180" />
                      )}
                    </Button>
                  </div>
                )}

                {!filteredTasks.length && !searchQuery && (
                  <ContentPlaceholder
                    Icon={Palmtree}
                    title={t('common:no_resource_yet', { resource: t('app:tasks').toLowerCase() })}
                    text={
                      <>
                        <Undo
                          size={200}
                          strokeWidth={0.2}
                          className="max-md:hidden absolute scale-x-0 scale-y-75 rotate-180 text-primary top-4 right-4 translate-y-20 opacity-0 duration-500 delay-500 transition-all group-hover/column:opacity-100 group-hover/column:scale-x-100 group-hover/column:translate-y-0 group-hover/column:rotate-[130deg]"
                        />
                        <p className="inline-flex gap-1 sm:opacity-0 duration-500 transition-opacity group-hover/column:opacity-100">
                          <span>{t('common:click')}</span>
                          <span className="text-primary">+</span>
                          <span className="max-sm:hidden text-primary">{t('app:task')}</span>
                          <span>{t('app:no_tasks.text')}</span>
                        </p>
                      </>
                    }
                  />
                )}
                {!filteredTasks.length && searchQuery && (
                  <ContentPlaceholder Icon={Search} title={t('common:no_resource_found', { resource: t('app:tasks').toLowerCase() })} />
                )}
              </div>
            </ScrollArea>
          )}
        </div>
      </div>
    </div>
  );
}
