import { type ChangeMessage, ShapeStream, type ShapeStreamOptions } from '@electric-sql/client';
import { config } from 'config';
import { useEffect } from 'react';
import { queryClient } from '~/lib/router';
import { useWorkspaceQuery } from '~/modules/workspaces/helpers/use-workspace';
import { useGeneralStore } from '~/store/general';
import type { Label, Project, Task } from '~/types/app';
import type { Member } from '~/types/common';
import { objectKeys } from '~/utils/object';
import { tasksTableColumns } from '#/db/schema/tasks';
import { env } from '../../../../../env';
import { tasksQueryOptions } from '../board-column';

type RawTask = {
  id: string;
  description: string;
  keywords: string;
  expandable: boolean;
  entity: 'task';
  summary: string;
  type: 'bug' | 'feature' | 'chore';
  impact: number;
  sort_order: number;
  status: number;
  parent_id: string;
  labels: string[];
  assigned_to: string[];
  organization_id: string;
  project_id: string;
  created_at: string;
  created_by: string;
  modified_at: string;
  modified_by: string;
};

const parseRawTask = (rawTask: RawTask, members: Member[], labels: Label[]): Task => {
  const task = {} as unknown as Task;
  for (const key of objectKeys(rawTask)) {
    const columnName = tasksTableColumns[key] as keyof Task;

    if (!columnName) {
      continue;
    }

    if (key === 'created_by' && columnName === 'createdBy') {
      task[columnName] = members.find((m) => m.id === rawTask[key]) ?? null;
      continue;
    }
    if (key === 'assigned_to' && columnName === 'assignedTo') {
      task[columnName] = members.filter((m) => rawTask[key].includes(m.id));
      continue;
    }
    if (key === 'labels' && columnName === 'labels') {
      task[columnName] = labels.filter((l) => rawTask[key].includes(l.id));
      continue;
    }
    if (key === 'modified_by' && columnName === 'modifiedBy') {
      task[columnName] = members.find((m) => m.id === rawTask[key]) ?? null;
      continue;
    }
    task[columnName] = rawTask[key] as never;
  }
  return task;
};

const taskShape = (organizationId: string, projectId?: string): ShapeStreamOptions => ({
  url: new URL(`/${organizationId}/tasks/shape-proxy`, config.backendUrl).href,
  where: projectId ? `project_id = '${projectId}'` : undefined,
  backoffOptions: {
    initialDelay: 500,
    maxDelay: 32000,
    multiplier: 2,
  },
  fetchClient: (input, init) =>
    fetch(input, {
      ...init,
      credentials: 'include',
    }),
});

export const useSync = (project: Project) => {
  const { networkMode } = useGeneralStore();
  const {
    data: { labels, members },
  } = useWorkspaceQuery();

  // Subscribe to task updates
  useEffect(() => {
    if (networkMode !== 'online' || !config.has.sync || !env.VITE_HAS_SYNC) return;

    const shapeStream = new ShapeStream<RawTask>(taskShape(project.organizationId, project.id));
    const queryKey = tasksQueryOptions({ projectId: project.id, orgIdOrSlug: project.organizationId }).queryKey;
    const unsubscribe = shapeStream.subscribe((messages) => {
      const createMessage = messages.find((m) => m.headers.operation === 'insert') as ChangeMessage<RawTask> | undefined;
      if (createMessage) {
        const value = createMessage.value;
        queryClient.setQueryData(queryKey, (data) => {
          if (!data || data.items.some((t) => t.id === value.id)) return data;
          const createdTask: Task = {
            ...parseRawTask(value, members, labels),
            subtasks: [],
          };
          return {
            ...data,
            items: [createdTask, ...data.items],
          };
        });
      }

      const updateMessage = messages.find((m) => m.headers.operation === 'update') as ChangeMessage<RawTask> | undefined;
      if (updateMessage) {
        const value = updateMessage.value;
        queryClient.setQueryData(queryKey, (data) => {
          if (!data) return;
          return {
            ...data,
            items: data.items.map((task) => {
              if (task.id === value.id) {
                const updatedTask: Task = {
                  ...task,
                  ...parseRawTask(value, members, labels),
                };
                return updatedTask;
              }

              return task;
            }),
          };
        });
      }

      const deleteMessage = messages.find((m) => m.headers.operation === 'delete') as ChangeMessage<RawTask> | undefined;
      if (deleteMessage) {
        queryClient.setQueryData(queryKey, (data) => {
          if (!data) return;
          return {
            ...data,
            items: data.items.filter((item) => item.id !== deleteMessage.value.id),
          };
        });
      }
    });
    return () => {
      unsubscribe();
    };
  }, [networkMode]);
};
