import { type QueryKey, useMutation } from '@tanstack/react-query';
import { t } from 'i18next';
import { toast } from 'sonner';
import { type GetTasksParams, createTask, deleteTasks, updateTask } from '~/api/tasks';
import { queryClient } from '~/lib/router';
import { offlineSummarySet } from '~/modules/tasks/helpers';
import type { Subtask, Task } from '~/types/app';
import { nanoid } from '~/utils/nanoid';

export type TasksCreateMutationQueryFnVariables = Parameters<typeof createTask>[0];

type TasksUpdateParams = Parameters<typeof updateTask>[0];
export type TasksUpdateMutationQueryFnVariables = Omit<TasksUpdateParams, 'data'> & {
  projectId?: string;
  data: TasksUpdateParams['data'] | { id: string }[];
};
export type TasksDeleteMutationQueryFnVariables = Parameters<typeof deleteTasks>[0] & {
  projectIds: string[];
  parentId?: string | null;
};

type QueryFnData = {
  items: Task[];
  total: number;
};

type InfiniteQueryFnData = {
  pageParams: number[];
  pages: QueryFnData[];
};

type ContextProp = [QueryKey, QueryFnData | InfiniteQueryFnData | Task | undefined, string | null];

export const taskKeys = {
  all: () => ['tasks'] as const,
  single: (id: string) => ['task', id] as const,
  lists: () => [...taskKeys.all(), 'list'] as const,
  list: (filters?: GetTasksParams) => [...taskKeys.lists(), filters] as const,
  table: (filters?: GetTasksParams) => [...taskKeys.all(), 'table', filters] as const,
  create: () => [...taskKeys.all(), 'create'] as const,
  update: () => [...taskKeys.all(), 'update'] as const,
  delete: () => [...taskKeys.all(), 'delete'] as const,
};

export const useTaskCreateMutation = () => {
  return useMutation<Task, Error, TasksCreateMutationQueryFnVariables>({
    mutationKey: taskKeys.create(),
    mutationFn: createTask,
  });
};

const transformUpdateData = (variables: TasksUpdateMutationQueryFnVariables) => {
  const transformedVariables = {
    ...variables,
    data: Array.isArray(variables.data) ? variables.data.map((item) => (typeof item === 'string' ? item : item.id)) : variables.data,
  };

  return transformedVariables;
};

export const useTaskUpdateMutation = () => {
  return useMutation<Pick<Task, 'summary' | 'description' | 'expandable'>, Error, TasksUpdateMutationQueryFnVariables>({
    mutationKey: taskKeys.update(),
    mutationFn: (variables) => updateTask(transformUpdateData(variables)),
  });
};

export const useTaskDeleteMutation = () => {
  return useMutation<boolean, Error, TasksDeleteMutationQueryFnVariables>({
    mutationKey: taskKeys.delete(),
    mutationFn: deleteTasks,
  });
};

const onError = (_: Error, __: TasksUpdateMutationQueryFnVariables & TasksCreateMutationQueryFnVariables, context?: ContextProp[]) => {
  if (!context || !context.length) return toast.error(t('common:error.create_resource', { resource: t('app:task') }));
  for (const [queryKey, previousData] of context) {
    queryClient.setQueryData(queryKey, previousData);
  }
};

queryClient.setMutationDefaults(taskKeys.create(), {
  mutationFn: createTask,
  onMutate: async (variables) => {
    const { id: taskId, organizationId, projectId, parentId, impact } = variables;

    const optimisticId = taskId || nanoid();
    const newTask: Task = {
      ...variables,
      // add summary for offline, cos creation of summary handled on BE
      summary: offlineSummarySet(variables.description),
      id: optimisticId,
      impact: impact || null,
      expandable: false,
      parentId: parentId || null,
      labels: [],
      subtasks: [],
      entity: 'task',
      assignedTo: [],
      createdAt: new Date().toISOString(),
      createdBy: variables.createdBy,
      modifiedAt: null,
      modifiedBy: null,
    };

    const projectQueries = await getPreviousTasks(projectId, organizationId);
    const context: ContextProp[] = [];

    for (const [queryKey, previousTasks] of projectQueries) {
      if (previousTasks) {
        queryClient.setQueryData<InfiniteQueryFnData | QueryFnData>(queryKey, (old) => {
          if (!old) return handleNoOld(previousTasks);
          const tasks = getTasks(old);
          const updatedTasks = addNewTask(tasks, newTask);
          return formatUpdatedData(old, updatedTasks);
        });
      }

      context.push([queryKey, previousTasks, optimisticId]);
    }

    // Update task sheet if new created if subtask
    if (parentId) {
      const [[queryKey, previousTask]] = getTaskQuery(parentId);
      if (previousTask) {
        queryClient.setQueryData<Task>(taskKeys.single(parentId), (oldParent) => {
          if (!oldParent) return;
          const [updatedTask] = addNewTask([oldParent], newTask);
          return updatedTask;
        });
      }
      context.push([queryKey, previousTask, optimisticId]);
    }

    return context;
  },
  onSuccess: (createdTask, { organizationId, projectId }, context) => {
    // Update task sheet if new created if subtask
    if (createdTask.parentId) {
      const [queryKey] = getTaskQuery(createdTask.parentId);
      if (queryKey) {
        const [_, __, optimisticId] = context.find(([key]) => JSON.stringify(key) === JSON.stringify(queryKey)) ?? [];
        queryClient.setQueryData<Task>(taskKeys.single(createdTask.parentId), (task) => {
          if (!task) return;
          const updatedSubtasks = task.subtasks.map((subtask) => (subtask.id === optimisticId ? createdTask : subtask));
          return { ...task, subtasks: updatedSubtasks };
        });
      }
    }

    const queries = getQueries(projectId, organizationId);

    for (const query of queries) {
      const [activeKey] = query;
      const [_, __, optimisticId] = context.find(([key]) => JSON.stringify(key) === JSON.stringify(activeKey)) ?? [];

      queryClient.setQueryData<InfiniteQueryFnData | QueryFnData>(activeKey, (oldData) => {
        if (!oldData) return oldData;
        const tasks = getTasks(oldData);
        const updatedTasks = tasks.map((task) => {
          // Update the task itself
          if (task.id === optimisticId) return createdTask;

          // If the task is the parent, update its subtasks
          if (task.id === createdTask.parentId) {
            const updatedSubtasks = task.subtasks.map((subtask) => (subtask.id === optimisticId ? createdTask : subtask));
            return { ...task, subtasks: updatedSubtasks }; // Return parent with updated subtasks
          }
          // No changes, return task as-is
          return task;
        });

        return formatUpdatedData(oldData, updatedTasks);
      });
    }
  },
  onError,
});

queryClient.setMutationDefaults(taskKeys.update(), {
  mutationFn: (variables) => updateTask(transformUpdateData(variables)),
  onMutate: async (variables: TasksUpdateMutationQueryFnVariables) => {
    const { orgIdOrSlug, projectId, id: taskId, key, data } = variables;
    const context: ContextProp[] = [];

    // if exist sheet task query update it
    const [[taskQueryKey, previousTask]] = getTaskQuery(taskId);
    if (previousTask) {
      queryClient.setQueryData<Task>(taskQueryKey, (oldTask) => {
        if (!oldTask) return;
        const [updatedTask] = updateTasks([oldTask], variables);
        return updatedTask;
      });
    }
    context.push([taskQueryKey, previousTask, null]);

    // if project id changed delete task from previous project and add to new
    if (key === 'projectId' && typeof data === 'string') {
      let oldTask: Task | undefined;
      const oldProjectQueries = await getPreviousTasks(projectId, orgIdOrSlug);
      for (const [queryKey, previousTasks] of oldProjectQueries) {
        if (previousTasks) {
          queryClient.setQueryData<InfiniteQueryFnData | QueryFnData>(queryKey, (old) => {
            if (!old) return handleNoOld(previousTasks);
            const tasks = getTasks(old);
            const task = tasks.find((task) => task.id === taskId);
            if (task) {
              oldTask = task;
              const updatedTasks = deletedTasks(tasks, [taskId]);
              return formatUpdatedData(old, updatedTasks);
            }
            return old;
          });
          context.push([queryKey, previousTasks, null]);
        }
      }

      if (oldTask) {
        const newTask: Task = {
          ...oldTask,
          [key]: data,
          order: variables.order || oldTask.order,
        };
        const newProjectQueries = await getPreviousTasks(data, orgIdOrSlug);
        for (const [queryKey, previousTasks] of newProjectQueries) {
          if (previousTasks) {
            queryClient.setQueryData<InfiniteQueryFnData | QueryFnData>(queryKey, (old) => {
              if (!old) return handleNoOld(previousTasks);
              const tasks = getTasks(old);
              const updatedTasks = addNewTask(tasks, newTask);
              return formatUpdatedData(old, updatedTasks);
            });
          }
          context.push([queryKey, previousTasks, null]);
        }
      }

      return context;
    }

    const projectQueries = await getPreviousTasks(projectId, orgIdOrSlug);

    for (const [queryKey, previousTasks] of projectQueries) {
      // Optimistically update to the new value
      if (previousTasks) {
        queryClient.setQueryData<InfiniteQueryFnData | QueryFnData>(queryKey, (old) => {
          if (!old) return handleNoOld(previousTasks);
          const tasks = getTasks(old);
          const updatedTasks = updateTasks(tasks, variables);
          return formatUpdatedData(old, updatedTasks);
        });
      }
      context.push([queryKey, previousTasks, null]);
    }
    return context;
  },
  onSuccess: async (updatedTask, { id: taskId, orgIdOrSlug }) => {
    // if exist sheet task query update it
    const [[queryKey, previousTask]] = getTaskQuery(taskId);
    if (previousTask) {
      queryClient.setQueryData<Task>(queryKey, (old) => {
        if (!old) return;
        const sheetTaskUpdated = { ...old, updatedTask };
        return sheetTaskUpdated;
      });
    }

    const queries = getQueries(updatedTask.projectId, orgIdOrSlug);

    for (const query of queries) {
      const [activeKey] = query;
      queryClient.setQueryData<InfiniteQueryFnData | QueryFnData>(activeKey, (oldData) => {
        if (!oldData) return oldData;
        const tasks = getTasks(oldData);
        const updatedTasks = tasks.map((task) => {
          // Update the task itself
          if (task.id === taskId) return { ...task, ...updatedTask };
          // If the task is the parent, update its subtasks
          if (task.subtasks) {
            const updatedSubtasks = task.subtasks.map((subtask) => (subtask.id === taskId ? { ...subtask, ...updatedTask } : subtask));
            return { ...task, subtasks: updatedSubtasks }; // Return parent with updated subtasks
          }
          // No changes, return task as-is
          return task;
        });

        return formatUpdatedData(oldData, updatedTasks);
      });
    }
  },
  onError,
});

queryClient.setMutationDefaults(taskKeys.delete(), {
  mutationFn: ({ ids, orgIdOrSlug }: TasksDeleteMutationQueryFnVariables) => deleteTasks({ ids, orgIdOrSlug }),
  onMutate: async (variables) => {
    const { ids, projectIds, orgIdOrSlug, parentId } = variables;

    const context: ContextProp[] = [];

    for (const projectId of projectIds) {
      const projectQueries = await getPreviousTasks(projectId, orgIdOrSlug);

      for (const [queryKey, previousTasks] of projectQueries) {
        // Optimistically update to the new value
        if (previousTasks) {
          queryClient.setQueryData<InfiniteQueryFnData | QueryFnData>(queryKey, (old) => {
            if (!old) return handleNoOld(previousTasks);
            const tasks = getTasks(old);
            const updatedTasks = deletedTasks(tasks, ids);
            return formatUpdatedData(old, updatedTasks);
          });
        }
        context.push([queryKey, previousTasks, null]);
      }
    }
    // remove from sheet task query if it was sub task
    if (parentId) {
      const [[queryKey, task]] = getTaskQuery(parentId);
      if (task) {
        queryClient.setQueryData<Task>(queryKey, (old) => {
          if (!old) return;
          const [updatedTasks] = deletedTasks([task], ids);
          return updatedTasks;
        });
      }
      // add context if deleted subtask
      context.push([queryKey, task, null]);
    } else {
      // invalidate sheet task query that was deleted
      for (const id of ids) queryClient.invalidateQueries({ queryKey: taskKeys.single(id) });
    }
    return context;
  },
  onError: async (_, __, context) => {
    if (!context || !context.length) return toast.error(t('common:error.delete_resources', { resources: t('app:tasks') }));

    for (const [queryKey, prevData] of context) {
      if (prevData) queryClient.setQueryData(queryKey, prevData);
    }
  },
});

// Helper functions
const getTasks = (prevItems: QueryFnData | InfiniteQueryFnData) => {
  return isQueryFnData(prevItems) ? prevItems.items : prevItems.pages.flatMap((page) => page.items);
};

const handleNoOld = (previousTasks: QueryFnData | InfiniteQueryFnData) => {
  const pages = {
    items: [],
    total: 0,
  };
  if (isQueryFnData(previousTasks)) return pages;
  return { pageParams: [0], pages: [pages] };
};

function addNewTask(tasks: Task[], newTask: Task) {
  if (tasks.some((el) => el.id === newTask.parentId)) {
    return tasks.map((task) => {
      // Update the parent task
      if (task.id === newTask.parentId) {
        const t = { ...task, subtasks: [...task.subtasks, newTask] };
        return t;
      }
      // No changes, return task as-is
      return task;
    });
  }
  return [...tasks, newTask].sort((a, b) => b.order - a.order);
}

function updateTasks(tasks: Task[], variables: TasksUpdateMutationQueryFnVariables) {
  return tasks.map((task) => {
    // Update the task itself
    if (task.id === variables.id) {
      const t = updateTaskProperty(task, variables);
      if (variables.order && variables.order !== t.order) t.order = variables.order;
      return t;
    }
    // If the task is the parent, update its subtasks
    if (task.subtasks) {
      //TODO maybe sort in some other way
      const updatedSubtasks = updateSubtasks(task.subtasks, variables.id, variables);
      return { ...task, subtasks: updatedSubtasks.sort((a, b) => b.order - a.order) }; // Return parent with updated subtasks
    }
    // No changes, return task as-is
    return task;
  });
}

function deletedTasks(tasks: Task[], ids: string[]) {
  return tasks
    .map((task) => {
      if (ids.includes(task.id)) return null;
      // If the task is the parent, delete from subtasks
      if (task.subtasks) {
        const updatedSubtasks = task.subtasks.filter((subtask) => !ids.includes(subtask.id));
        return { ...task, subtasks: updatedSubtasks };
      }
      return task;
    })
    .filter(Boolean) as Task[];
}

const formatUpdatedData = (oldData: InfiniteQueryFnData | QueryFnData, updatedTasks: Task[]) => {
  if (isQueryFnData(oldData)) return { total: updatedTasks.length, items: updatedTasks };

  return { ...oldData, pages: [{ total: updatedTasks.length, items: updatedTasks }] };
};

// Helper function to update a task property
const updateTaskProperty = <T extends Task | Subtask>(task: T, variables: TasksUpdateMutationQueryFnVariables): T => {
  return { ...task, [variables.key]: variables.data };
};

// Helper function to update a subtask within the parent
const updateSubtasks = (subtasks: Subtask[], taskId: string, variables: TasksUpdateMutationQueryFnVariables) => {
  return subtasks.map((subtask) => {
    if (subtask.id === taskId) return updateTaskProperty(subtask, variables); // Update the subtask

    return subtask; // No changes
  });
};

// Type guard to determine if the data is QueryFnData
const isQueryFnData = (data: unknown): data is QueryFnData => {
  return typeof data === 'object' && data !== null && 'items' in data && 'total' in data;
};

const getPreviousTasks = async (projectId: string | undefined, orgIdOrSlug: string) => {
  // Snapshot the previous value
  const queries = getQueries(projectId, orgIdOrSlug);

  for (const query of queries) {
    const [queryKey, _] = query;
    // Cancel any outgoing refetches
    // (so they don't overwrite our optimistic update)
    await queryClient.cancelQueries({ queryKey });
  }
  return queries;
};

const getTableQueries = (orgIdOrSlug: string) => {
  return queryClient.getQueriesData<InfiniteQueryFnData>({ queryKey: taskKeys.table({ orgIdOrSlug }) });
};

const getBoardQueries = (projectId: string | undefined, orgIdOrSlug: string): [QueryKey, QueryFnData | undefined][] => {
  const queryKey = taskKeys.list({ orgIdOrSlug, projectId });
  return [[queryKey, queryClient.getQueryData<QueryFnData>(queryKey)]];
};

const getTaskQuery = (taskId: string): [QueryKey, Task | undefined][] => {
  const queryKey = taskKeys.single(taskId);
  return [[queryKey, queryClient.getQueryData<Task>(queryKey)]];
};

const getQueries = (projectId: string | undefined, orgIdOrSlug: string): [QueryKey, InfiniteQueryFnData | QueryFnData | undefined][] => {
  const tableQueries = getTableQueries(orgIdOrSlug);
  const boardQueries = getBoardQueries(projectId, orgIdOrSlug);

  return [...tableQueries, ...boardQueries];
};
