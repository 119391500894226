import { Card, CardContent } from '~/modules/ui/card';
import { cn } from '~/utils/cn';

import type { TaskStatus } from '~/modules/tasks/task-dropdowns/select-status';
import { taskTypes } from '~/modules/tasks/task-dropdowns/select-task-type';
import { TaskFooter } from '~/modules/tasks/task-footer';
import type { Task } from '~/types/app';
import { taskVariants } from './task';

export const CardPrimitive = ({ task }: { task: Task }) => {
  return (
    <Card
      tabIndex={0}
      className={cn(
        'rounded-none border bg-card/50 opacity-60 is-collapsed',
        taskVariants({
          status: task.status as TaskStatus,
        }),
      )}
    >
      <CardContent className="p-4 space-between flex flex-col">
        <div className="flex flex-row gap-1 w-full">
          <div className="-ml-0.5">{taskTypes[taskTypes.findIndex((t) => t.value === task.type)]?.icon() || ''}</div>

          <div
            // biome-ignore lint/security/noDangerouslySetInnerHtml: is sanitized by backend
            dangerouslySetInnerHTML={{ __html: task.summary }}
            className="m-1 bn-container bn-shadcn leading-none inline"
          />
        </div>
        <TaskFooter task={task} isSheet={false} isSelected={false} isStatusDropdownOpen={false} />
      </CardContent>
    </Card>
  );
};
