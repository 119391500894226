import { getTableColumns } from 'drizzle-orm';
import { toSnakeCase } from 'drizzle-orm/casing';
import { integer, pgTable, timestamp, varchar } from 'drizzle-orm/pg-core';
import { nanoid } from '#/utils/nanoid';
import { organizationsTable } from './organizations';
import { projectsTable } from './projects';
import { usersTable } from './users';

export const labelsTable = pgTable('labels', {
  id: varchar().primaryKey().$defaultFn(nanoid),
  name: varchar().notNull(),
  color: varchar(),
  entity: varchar({ enum: ['label'] })
    .notNull()
    .default('label'),
  createdAt: timestamp().defaultNow().notNull(),
  lastUsedAt: timestamp().defaultNow().notNull(),
  createdBy: varchar().references(() => usersTable.id, {
    onDelete: 'set null',
  }),
  modifiedAt: timestamp(),
  modifiedBy: varchar().references(() => usersTable.id, {
    onDelete: 'set null',
  }),
  useCount: integer().notNull(),
  organizationId: varchar()
    .notNull()
    .references(() => organizationsTable.id, {
      onDelete: 'cascade',
    }),
  projectId: varchar()
    .notNull()
    .references(() => projectsTable.id, {
      onDelete: 'cascade',
    }),
});

// Get table columns and convert to snake_case
export const labelsTableColumns = Object.fromEntries(
  Object.entries(getTableColumns(labelsTable)).map(([key, column]) => [toSnakeCase(column.name), key]),
);

export type LabelModel = typeof labelsTable.$inferSelect;
export type InsertLabelModel = typeof labelsTable.$inferInsert;
