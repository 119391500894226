import { zodResolver } from '@hookform/resolvers/zod';
import type { UseFormProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { z } from 'zod';

import { useEffect, useMemo, useState } from 'react';
import { toast } from 'sonner';
import { getMembers } from '~/api/memberships';
import { assignProjectWorkspace, getProjects } from '~/api/projects';
import { useFormWithDraft } from '~/hooks/use-draft-form';
import { useMutation } from '~/hooks/use-mutations';
import { isDialog as checkDialog, dialog } from '~/modules/common/dialoger/state';
import SelectParentFormField from '~/modules/common/form-fields/select-parent';
import UnsavedBadge from '~/modules/common/unsaved-badge';
import { Button } from '~/modules/ui/button';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '~/modules/ui/form';
import { useUserStore } from '~/store/user';
import type { Project } from '~/types/app';
import { workspaceIdQuerySchema } from '#/modules/projects/schema';
import { entityInOrgParamSchema } from '#/utils/schema/common-schemas';
import { addMenuItem } from '../common/nav-sheet/helpers/menu-operations';
import Combobox from '../ui/combobox';
import { useWorkspaceQuery } from '../workspaces/helpers/use-workspace';

interface SelectProjectFormProps {
  callback?: () => void;
  dialog?: boolean;
}

const formSchema = workspaceIdQuerySchema.merge(entityInOrgParamSchema);
type FormValues = z.infer<typeof formSchema>;

export const SelectProjectForm: React.FC<SelectProjectFormProps> = ({ dialog: isDialog }) => {
  const { t } = useTranslation();
  const { user } = useUserStore();

  const [allProject, setAllProject] = useState<Project[]>([]);
  const {
    data: { workspace },
    addProject,
  } = useWorkspaceQuery();

  const formOptions: UseFormProps<FormValues> = useMemo(
    () => ({
      resolver: zodResolver(formSchema),
      defaultValues: {
        idOrSlug: '',
        orgIdOrSlug: workspace.organizationId,
        workspaceId: workspace.id,
      },
    }),
    [workspace.id],
  );

  const form = useFormWithDraft<FormValues>('select-project', formOptions);

  const { mutate: create, isPending } = useMutation({
    mutationFn: assignProjectWorkspace,
    onSuccess: async (newProject) => {
      form.reset();
      toast.success(t('app:success.assign_resource', { resource: `${t('app:project')} ${newProject.name}`, secondResource: workspace.name }));
      const { items } = await getMembers({
        idOrSlug: newProject.id,
        orgIdOrSlug: newProject.organizationId,
        entityType: newProject.entity,
      });
      if (isDialog) dialog.remove();
      addProject(newProject, items || []);
      addMenuItem(newProject, 'workspaces', workspace.slug);
    },
  });

  const options = useMemo(() => {
    const filteredItems = allProject.filter((el) => el.membership?.workspaceId !== workspace.id);
    return filteredItems.map((item) => ({ value: item.id, label: item.name, url: item.thumbnailUrl }));
  }, [allProject]);

  const onSubmit = (values: FormValues) => create(values);

  useEffect(() => {
    (async () => {
      const response = await getProjects({ userId: user.id, orgIdOrSlug: workspace.organizationId });
      setAllProject(response.items);
    })();
  }, []);

  useEffect(() => {
    if (form.unsavedChanges) {
      const targetDialog = dialog.get('create-project');
      if (targetDialog && checkDialog(targetDialog)) {
        dialog.update('create-project', { title: <UnsavedBadge title={targetDialog?.title} /> });
      }
    } else {
      dialog.reset('create-project');
    }
  }, [form.unsavedChanges]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <FormField
          control={form.control}
          name="idOrSlug"
          render={({ field: { onChange } }) => (
            <FormItem name={'idOrSlug'}>
              <FormLabel>
                {t('app:project')}
                <span className="ml-1 opacity-50">*</span>
              </FormLabel>
              <FormControl>
                <Combobox
                  options={options}
                  contentWidthMatchInput={true}
                  name="idOrSlug"
                  onChange={onChange}
                  placeholder={t('common:select_resource', { resource: t('app:project').toLowerCase() })}
                  searchPlaceholder={t('common:search')}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <SelectParentFormField collection="workspaces" type="project" control={form.control} label={t('app:workspace')} name="workspaceId" disabled />
        <div className="flex flex-col sm:flex-row gap-2">
          <Button type="submit" disabled={!form.formState.isDirty} loading={isPending}>
            {t('common:select')}
          </Button>
          <Button
            type="reset"
            variant="secondary"
            className={form.formState.isDirty ? '' : 'invisible'}
            aria-label="Cancel"
            onClick={() => form.reset()}
          >
            {t('common:cancel')}
          </Button>
        </div>
      </form>
    </Form>
  );
};
