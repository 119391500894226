import { index, pgTable, timestamp, varchar } from 'drizzle-orm/pg-core';
import { usersTable } from '#/db/schema/users';
import { nanoid } from '#/utils/nanoid';
import { organizationsTable } from './organizations';

export const workspacesTable = pgTable(
  'workspaces',
  {
    id: varchar().primaryKey().$defaultFn(nanoid),
    entity: varchar({ enum: ['workspace'] })
      .notNull()
      .default('workspace'),
    name: varchar().notNull(),
    slug: varchar().unique().notNull(),
    organizationId: varchar()
      .notNull()
      .references(() => organizationsTable.id, {
        onDelete: 'cascade',
      }),
    thumbnailUrl: varchar(),
    bannerUrl: varchar(),
    createdAt: timestamp().defaultNow().notNull(),
    createdBy: varchar().references(() => usersTable.id, {
      onDelete: 'set null',
    }),
    modifiedAt: timestamp(),
    modifiedBy: varchar().references(() => usersTable.id, {
      onDelete: 'set null',
    }),
  },
  (table) => {
    return {
      nameIndex: index('workspace_name_index').on(table.name.desc()),
      createdAtIndex: index('workspace_created_at_index').on(table.createdAt.desc()),
    };
  },
);

export type WorkspaceModel = typeof workspacesTable.$inferSelect;
export type InsertWorkspaceModel = typeof workspacesTable.$inferInsert;
